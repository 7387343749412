import { useParams } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import Hotel from "../components/hotel";

const HotelPage = () => {
  const {hotelId} = useParams();

  return (
    <>
      <Header />
      <Hotel hotelId={hotelId} />
      <Footer />
    </>
  );
};

export default HotelPage;
