import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import AuthContextProvider from "../store/authContext";
import Welcome from "../pages/Welcome";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import HotelsPage from "../pages/HotelsPage";
import GallerPage from "../pages/GallerPage";
import HotelPage from "../pages/HotelPage";

export default function Main() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/welcome" element={<Welcome />} />
          <Route exact path="/hotels" element={<HotelsPage />} />
          <Route exact path="/hotel/:hotelId" element={<HotelPage />} />
          <Route exact path="/gallery" element={<GallerPage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/contact" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}
