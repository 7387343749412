import { useContext, useEffect } from "react";
import { authContext } from "../store/authContext";
import Header from "../components/header";
import Footer from "../components/footer";
import WelcomeOptions from "../components/welcomeOptions";

const Welcome = () => {
  const authCtx = useContext(authContext);

  useEffect(() => {
    if (authCtx.isAuthenticated) {
      window.location = "/";
    }
  }, [authCtx]);

  return (
    <>
      <Header />
      <WelcomeOptions />
      <Footer />
    </>
  );
};

export default Welcome;
