const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className=" col-md-4">
              <h3>Contact US</h3>
              <ul className="conta">
                <li>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>Ranchi
                </li>
                <li onClick={() => {window.location='tel:+919155596557'}}>
                  <i className="fa fa-mobile" aria-hidden="true"></i>+919155596557
                </li>
                <li>
                  {" "}
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <a href="email:support@oxyhotels.com"> support@oxyhotels.com </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <h3>Menu Link</h3>
              <ul className="link_menu">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/hotels">Hotels</a>
                </li>
                <li>
                  <a href="/gallery">Gallery</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <h3>News letter</h3>
              <form className="bottom_form">
                <input
                  className="enter"
                  placeholder="Enter your email"
                  type="text"
                  name="Enter your email"
                />
                <button className="sub_btn">subscribe</button>
              </form>
              <ul className="social_icon">
                <li>
                  <a href="facebook.com/oxyhotels">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="twitter.com/oxyhotels">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="linkdin.com/oxyhotels">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="youtube.com/oxyhotels">
                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <p>© 2019 All Rights Reserved Oxyhotels</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer