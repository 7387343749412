import Footer from "../components/footer"
import Gallery from "../components/gallery"
import Header from "../components/header"


const GallerPage = () => {

    return (
        <>
        <Header />
        <Gallery />
        <Footer />
        </>
    )

}

export default GallerPage