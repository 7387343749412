import About from "../components/about"
import Banner from "../components/banner"
import Contact from "../components/contact"
import Footer from "../components/footer"
import Header from "../components/header"
import Hotels from "../components/hotels"


const Home = () => {
    return (
        <>
        <Header />
        <Banner />
        <About />
        <Hotels />
        <Contact />
        <Footer />
        </>
    )
}

export default Home