import { createContext, useState } from "react";
import axios from "axios";

const authSample = {
    isAuthenticated:false,
    isAuthenticating:false,
    isHotelLoaded:false,
    hotels:[],
    loadAllHotels : async () => {}
}

export const authContext = createContext(authSample)

const AuthContextProvider = ({children}) => {


    const [hotels,setHotels] = useState([])
    const [isHotelLoaded,setIsHotelLoaded] = useState(false)
    const loadAllHotels =async () => {
        try{
            const response = await axios.post("https://oxyhotels.com/admin/getHotel")
            let data = JSON.parse(response.data.data)
            setHotels(data)
            setIsHotelLoaded(true)
        }catch(err){
            console.log(err)
        }
    }

    const value = {
        isAuthenticated:false,
        isAuthenticating:false,
        isHotelLoaded:isHotelLoaded,
        hotels:hotels,
        loadAllHotels:loadAllHotels
    }


    return (
        <authContext.Provider value={value} >
            {children}
        </authContext.Provider>
    )

}

export default AuthContextProvider