const Banner = () => {
  return (
    <section className="banner_main">
      <div id="myCarousel" className="carousel slide banner" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
          <li data-target="#myCarousel" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="first-slide"
              src={`${process.env.REACT_APP_DOMAIN}/images/banner1.jpg`}
              alt="First slide"
            />
            <div className="container"></div>
          </div>

          <div className="carousel-item">
            <img
              className="second-slide"
              src={`${process.env.REACT_APP_DOMAIN}/images/banner2.jpg`}
              alt="Second slide"
            />
            <div className="container"></div>
          </div>

          <div className="carousel-item">
            <img
              className="third-slide"
              src={`${process.env.REACT_APP_DOMAIN}/images/banner3.jpg`}
              alt="Third slide"
            />
            <div className="container"></div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#myCarousel"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#myCarousel"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
      <div className="booking_ocline">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="book_room">
                <h1>Book a Room Online</h1>
                <form className="book_now">
                  <div className="row">
                    <div className="col-md-12">
                      <span>Arrival </span>
                      <input
                        className="online_book"
                        placeholder="DD/MM/YYYY"
                        type="date"
                        name="arrivalDate"
                      />
                    </div>
                    <div className="col-md-12">
                      <span>Departure</span>
                      <input
                        className="online_book"
                        placeholder="DD/MM/YYYY"
                        type="date"
                        name="departureDate"
                      />
                    </div>
                    <div className="col-md-12">
                      <button className="book_btn">Book Now</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
