import { useContext, useEffect, useState } from "react";
import { authContext } from "../store/authContext";

const Hotel = ({ hotelId }) => {
  const [hotel, setHotel] = useState(undefined);
  const authCtx = useContext(authContext);

  useEffect(() => {
    if (!authCtx.isHotelLoaded) {
      authCtx.loadAllHotels();
    } else {
      setHotel(authCtx.hotels.find((hot) => hot._id === hotelId));
      console.log(
        Object.values(
          authCtx.hotels.find((hot) => hot._id === hotelId).imageData
        )
          .map((value) => {
            return value;
          })
          .flat()
      );
    }
  }, [authCtx, setHotel, hotelId, authCtx.hotels]);

  return (
    <>
      <div className="slider">
        {hotel &&
          Object.values(hotel.imageData)
            .map((value) => {
              return value;
            })
            .flat()
            .map((image) => {
              return (
                <div className="hotelSlide">
                  {" "}
                  <img alt="hotel" src={`${process.env.REACT_APP_DOMAIN}${image}`} />
                </div>
              );
            })}
      </div>

      {hotel && (
        <div id="hotelDetailsAndBookingContainer">
          <div className="hotelDetails">
            <div className="hotelDescription">
              <h3 style={{color:"white"}} > {hotel.hotelName}</h3>
              <p  style={{color:"white"}}> {hotel.hotelAddress} </p>
            </div>

            {/* <div id="bookingCont">
              <h3>Book</h3>

              <div id="datePicker">
                <label  style={{color:"white"}} for="">Stays</label>
                <input
                  type="text"
                  name="daterange"
                  id="daterange"
                  value="01/01/2018 - 01/15/2018"
                />
              </div>

              <h3  style={{color:"white"}}>Rooms and Guests</h3>
              <div id="roomsAndGuests"></div>

              <div id="bootBt">
                <button >
                  <h3
                    style={{
                      fontSize: "18px",
                      color: "#262626",
                      padding: "0px 0px",
                      margin: "0px 0px",
                    }}
                  >
                    Book
                  </h3>
                </button>
              </div>
            </div> */}

            <h3> Description </h3>
            <p  style={{color:"white"}}>{hotel.hotelDescription}</p>

            <h3> Amenities </h3>
            <div id="amenities">
              {hotel.houseAmenities.map((am) => {
                return <div  style={{color:"white"}} className="amenities">{am}</div>;
              })}
            </div>

            <h3> House Policies Dos</h3>
            <div className="housePolicies">
              {hotel.housePoliciesDos.map((hm) => {
                return <div  style={{color:"white"}} className="housePoliciesItem">{hm}</div>;
              })}
            </div>

            <h3> House Policies Donts</h3>
            <div className="housePolicies">
              {hotel.housePoliciesDonts.map((hm) => {
                return <div  style={{color:"white"}} className="housePoliciesItem">{hm}</div>;
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Hotel;
