import { useContext, useEffect } from "react";
import { authContext } from "../store/authContext";

const Hotels = () => {
  const authCtx = useContext(authContext);

  useEffect(() => {
    if(!authCtx.isHotelLoaded){
        authCtx.loadAllHotels()
    }
  }, [authCtx]);

  return (
    <div className="our_room">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titlepage">
              <h2>Our Hotels</h2>
              <p>Lorem Ipsum available, but the majority have suffered </p>
            </div>
          </div>
        </div>
        <div className="row">
        {authCtx.hotels.map((hotel) => {
          return (
            <div key={hotel._id} className="col-md-4 col-sm-6">
              <div
                id="serv_hover"
                className="room"
                onClick={() => {window.location = `/hotel/${hotel._id}`}}
              >
                <div className="room_img">
                  <figure>
                    <img
                      style={{objectFit:"cover",height:"330px"}}
                      src={`${process.env.REACT_APP_DOMAIN}${hotel.imageData[Object.keys(hotel.imageData)[0]][0]}`}
                      alt="#"
                    />
                  </figure>
                </div>
                <div className="bed_room">
                  <h3> {hotel.hotelName} </h3>
                  <p style={{color:"white"}}> {hotel.hotelDescription}</p>
                </div>
              </div>
            </div>
          );
        })}
        </div>
      </div>
    </div>
  );
};

export default Hotels;
