import About from "../components/about";
import Footer from "../components/footer";
import Header from "../components/header";

const AboutPage = () => {
  return (
    <>
      <Header />
      <div class="back_re">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="title">
                <h2>About Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <About />
      <Footer />
    </>
  );
};
export default AboutPage;
