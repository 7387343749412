import Footer from "../components/footer"
import Header from "../components/header"
import Hotels from "../components/hotels"

const HotelsPage = () => {

    return (
        <>
        <Header />
        <Hotels />
        <Footer />
        </>
    )
}

export default HotelsPage