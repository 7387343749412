import { useContext, useEffect } from "react";
import { authContext } from "../store/authContext";

const Gallery = () => {
  const authCtx = useContext(authContext);

  useEffect(() => {
    if (!authCtx.isHotelLoaded) {
      authCtx.loadAllHotels();
    }
  }, [authCtx]);

  const allValues = [];

  authCtx.hotels
    .map((hot) => hot.imageData)
    .forEach((obj) => {
      Object.values(obj).forEach((value) => {
        if (Array.isArray(value)) {
          allValues.push(...value);
        }
      });
    });

  return (
    <div className="gallery">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titlepage">
              <h2>gallery</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {allValues.map((image) => {
            return (
              <div className="col-md-3 col-sm-6">
                <div className="gallery_img">
                  <figure style={{ height: "100%" }}>
                    <img
                      style={{ height: "100%", objectFit: "cover" }}
                      src={`${process.env.REACT_APP_DOMAIN}${image}`}
                      alt="#"
                    />
                  </figure>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
