const WelcomeOptions = () => {
  return (
    <>
      <div className="wrapper">
        <div className="main-content">
          <div className="bigtext">
            <h1>Welcome to Oxyhotels</h1>
          </div>

          <div className="nomral-button icon-button" onClick={() => {window.location='/verifyEmailOrPhone'}}>
            <button style={{border:"none"}} >
              <i className="material-icons">call</i>
              <span>Continue With Phone</span>
            </button>
          </div>

          <div className="nomral-button icon-button" onClick={() => {window.location='/signInWithGoogle'}}>
            <button style={{border:"none"}} >
              <img src={`${process.env.REACT_APP_DOMAIN}/images/google.png`} alt="" />{" "}
              <span>Continue With Google</span>
            </button>
          </div>

          <div className="nomral-button with-email" onClick={()=>{window.location='/verifyEmailOrPhone'}}>
            <button style={{border:"none"}} >
              Continue With Email
            </button>
          </div>

          <div className="orTextContainer">
            <hr></hr>
            <p className="or">or</p>
            <hr></hr>
          </div>

          <div className="primary-button" onClick={() => {window.location='/verifyEmailOrPhone'}}>
            <button  style={{border:"none"}}>
              Continue With Phone
            </button>
          </div>

          <div className="noaccount">Don't Have An Account? Sign Up</div>
        </div>
      </div>
    </>
  );
};


export default WelcomeOptions